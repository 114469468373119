import $ from 'jquery';
import VideoCpn from '../components/VideoCpn';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


export class CommonViewLogic implements IView {
    //-----------------------------------------------------------------
    // Properties
    //-----------------------------------------------------------------
    private busy:boolean = false;
    private body:HTMLElement = document.querySelector('body');
    //-----------------------------------------------------------------
    // Init
    //-----------------------------------------------------------------

    constructor(context: any) {

        // init video instances
        const videoElems:any = document.querySelectorAll('.js-video');
        if (videoElems && videoElems.length) {
            // init each instance video
            for (var videoElem of videoElems) {
                new VideoCpn(this, videoElem);
            };
        }

    }

    public init() {

        this.addListener();

        $(window).on("load", function(this) {
            $('.preLoader').removeClass('preLoader--loading');
            // $('body').removeClass('--locked');
        });
    }

    private addListener() {
        $(document).on('click', '.js-open-panel', this.handlerOpenPanel.bind(this, false));
        $(document).on('click', '.js-open-panel-url', this.handlerOpenPanel.bind(this, true));
        $(document).on('click', '.js-close-panel', this.handlerClosePanel.bind(this));
        $(document).on('click', '.js-change-url', this.handlerChangeUrl.bind(this));
        $(document).on('click', '.js-lock-scroll', this.handlerLockScroll.bind(this));
        $(document).on('click', '.js-unlock-scroll', this.handlerUnlockScroll.bind(this));

        // @TODO Clean this

        // Not even used
        // $(window).scroll(function () {
        //     if ($('html, body').scrollTop() >= 300) {
        //         $('#header').addClass('scrll');
        //     } else {
        //         $('#header').removeClass('scrll');
        //     }
        // });

        // Not sure if it being used or not
        $('.cycle-slideshow').on('cycle-before', function (opts) { var slideshow = $(this); var img = slideshow.find('.banner-background').css('background-image'); slideshow.css('background-image', img); });
        var progress = $('#progress'), slideshow = $('.cycle-slideshow');
        slideshow.on('cycle-initialized cycle-before', function (e, opts) { progress.stop(true).css('width', 0); });
        slideshow.on('cycle-initialized cycle-after', function (e, opts) { if (!slideshow.is('.cycle-paused')) progress.animate({ width: '100%' }, opts.timeout, 'linear'); });
        slideshow.on('cycle-paused', function (e, opts) { progress.stop(); });
        slideshow.on('cycle-resumed', function (e, opts, timeoutRemaining) { progress.animate({ width: '100%' }, timeoutRemaining, 'linear'); });
        var slideshows = $('.cycle-slideshow').on('cycle-next cycle-prev', function (e, opts) {
            // advance the other slideshow
            slideshows.not(this).cycle('goto', opts.currSlide);
        });
        $('#cycle-2 .cycle-slide').click(function () {
            var index = $('#cycle-2').data('cycle.API').getSlideIndex(this);
            slideshows.cycle('goto', index);
        });

        $('.load_btn').click(function () {
            var src = $(this).data('scroll'),
                ths = $(this);
            $('.post-panel' + src).show().animate({
                right: 0,
            }, 800);
            setTimeout(function () {
                $('.post-panel' + src).addClass('active');
            }, 800);
        });

        $('.close_btn, .post-panel .clsBar').click(function () {
            var ths = $(this);
            $('.post-panel').animate({
                right: '-100%',
            }, 800);
            //$('.post-panel'+src).addClass('active');
            $('body').removeClass('');
            $('.post-panel').removeClass('active');
            setTimeout(function () {
                $('.post-panel').hide().removeClass('active');
            }, 800);
        });

        $('#pull').click(function () {
            $('.navbar .collapse').slideToggle();
        });
    }

    //-----------------------------------------------------------------
    // Event Handlers
    //-----------------------------------------------------------------
    private handlerOpenPanel(newUrl, evt) {
        evt.preventDefault();
        if (this.busy) {
            return false;
        }

        this.busy = true;

        if (newUrl) {
            const el = evt.currentTarget;
            const url = el.getAttribute('data-url');

            this.changeUrl(url);
            this.lockScroll();
        }

        const src = $(evt.currentTarget).data('scroll');
        $('.post-panel' + src).show().animate({
            right: 0,
        }, 800);

        setTimeout(() => {
            $('.post-panel' + src).addClass('active');
            this.busy = false;
        }, 800);
    }

    private handlerClosePanel(evt) {
        evt.preventDefault();

        if (this.busy) {
            return false;
        }

        this.busy = true;

        $('.post-panel.active').animate({
            right: '-100%',
        }, 800);
        $('body').removeClass('');
        $('.post-panel').removeClass('active');
        setTimeout(() => {
            $('.post-panel').hide();
            this.busy = false;
        }, 800);
    }

    private handlerChangeUrl(evt) {
        evt.preventDefault();

        const el = evt.currentTarget;
        const url = el.getAttribute('data-url');

        this.changeUrl(url);
    }

    private handlerLockScroll(evt) {
        evt.preventDefault();

        this.lockScroll();
    }

    private handlerUnlockScroll(evt) {
        evt.preventDefault();

        this.unlockScroll();
    }

    //-----------------------------------------------------------------
    // Methods
    //-----------------------------------------------------------------
    private changeUrl(url) {
        if (history.pushState) {
            history.pushState(null,null, url);
        }
    }

    private lockScroll() {
        this.body.classList.add('--locked');
        disableBodyScroll();
    }

    private unlockScroll() {

        this.body.classList.remove('--locked');
        enableBodyScroll();
    }
}


import $ from 'jquery';

export class Home implements IView {
    //-----------------------------------------------------------------
    // Properties
    //-----------------------------------------------------------------

    //-----------------------------------------------------------------
    // Init
    //-----------------------------------------------------------------
    constructor(context: any) {}

    public init() {


        $(window).on("load", function(this) {
            $('body').removeClass('--locked');
        });
    }


    //-----------------------------------------------------------------
    // Event Handlers
    //-----------------------------------------------------------------


    //-----------------------------------------------------------------
    // Methods
    //-----------------------------------------------------------------
}

import * as Player from "@vimeo/player/dist/player.js";

export default class VideoCpn {

    //-----------------------------------------------------------------
    // Init
    //-----------------------------------------------------------------
    constructor(context, el) {

        if (!el) {
            return;
        }

        this.el = el;
        this.busy = false;
        this.videoUrl = this.el.getAttribute('data-video');
        this.videoTitle = this.el.getAttribute('data-title');
        this.videoDesc = this.el.getAttribute('data-description1');
        this.modal = this.el.querySelector('.mVideo');
        this.modalBg = this.el.querySelector('.mVideo__bg');
        this.modalClose = this.el.querySelector('.mVideo__close');
        this.modalOpen = this.el.querySelector('.js-modal-open');
        this.videoWrapper = this.modal.querySelector('.js-videoplayer');

        if (!this.el.classList.contains('js-video--youtube')) {
            this.options = {
                url: this.videoUrl,
                responsive: true,
                maxwidth: 1024,
                loop: true
            }
            this.player = new Player(this.videoWrapper, this.options);
            this.player.pause();
        }
        this.init();
    }

    init() {
        this.addListeners();
    }

    addListeners() {
        $(this.modalOpen).on('click', this.openModal.bind(this));
        $(this.modalBg).on('click', this.closeModal.bind(this));
        $(this.modalClose).on('click', this.closeModal.bind(this));
    }

    //-----------------------------------------------------------------
    // Event Handlers
    //-----------------------------------------------------------------
    openModal(ev) {
        if (this.busy == false) {
            this.busy = true;
            $(this.modal).addClass('mVideo--active');
            setTimeout(() => {
                this.busy = false;
            }, 450);
        }
    }

    closeModal(ev) {
        if (this.busy == false) {
            this.busy = true;
            $(this.modal).removeClass('mVideo--active');

            if (!this.el.classList.contains('js-video--youtube')) {
                this.player.pause();
            }
            else {
                // $('#'+this.videoId).stopVideo();
            }

            setTimeout(() => {
                this.busy = false;
            }, 450);
        }
    }
}
/**
 * Utils
 */
import { appState } from './utils/state';

/**
 * Constants
 */
// import { AppEnv } from '@utils/constants';

/**
 * Views
 */
import { CommonViewLogic } from './views/common';
import { Home } from './views/home';

/**
 * Views List
 */
const views: Map<string, (context) => IView> = new Map();
// @TODO BETTER way to do this. won't work on single post
views.set('Home.php', context => new Home(this));

/**
 * Load the current view
 */
export function loadViews() {
    const currentViewName: string = appState.get('currentView');
    const currentView: (context) => IView = views.get(currentViewName);

    // Load common view logic
    new CommonViewLogic(this).init();

    if (currentView) {
        currentView(this).init();
    }

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

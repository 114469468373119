/**
 * Polyfills
 * ---------
 * https://babeljs.io/docs/usage/polyfill/
 *
 */
require('babel-polyfill');

/**
 * Global jQuery
 */
import $ from 'jquery';

/**
 * Views
 */
import { loadViews } from './app';

/**
 * Utils
 */
import { appState } from './utils/state';

import '../../css/scss/app.scss';

/**
 * Init application when document is ready
 */
const init = () => {
    // Init application state
    // appState.set('lang', $('html').attr('lang'));
    appState.set('currentView', $('.main-container').data('view'));

    // Load capabilities
    // addCapabilities();

    // Load views
    loadViews();
};

document.addEventListener('DOMContentLoaded', init);
